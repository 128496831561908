:root {
  --primary-color: #004987;
  --accent-color: #CCDBE7;
  --border-color: #E0E0E1;
  --light-grey-color: #F3F3F3;
  --cancel-red: #AA182C;
  --cancel-red-hover: #851425;
  --submit-green: #398232;
  --submit-green-hover: #2B6126;
  --next-button-hover: #003A6C;
  --primary-font-size: 16px;
  --header-font-size: 18px;
  --subheader-font-size: 14px;
}
* {
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--primary-font-size);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  /* color: var(--link-hover-color); */
  text-decoration: underline;
}

.active {
  background-color: var(--accent-color);
}

.loadingScreen {
  height: 100vh;
  background-image: url('../assets/loadingScreen.svg');
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinningLoadingIcon {
  margin-bottom: 25%;
  border-radius: 25%;
  animation: rotate 5s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rickOrcutt * {
  font-family: Comic Sans MS, Comic Sans, cursive;
}
.rickOrcutt *:not(.oniniteName, #branchName) {
  font-family: Comic Sans MS, Comic Sans, cursive;
  color: var(--primary-color);
}
.rickOrcuttHeader {
  background-image: url('../assets/loadingScreen.svg');
}
.rickOrcuttHeader * {
  font-family: Comic Sans MS, Comic Sans, cursive;
}

ul {
  list-style-image: url('../assets/bulletPoint.svg');
}

/* for the 3rd party select */
.css-14el2xx-placeholder {
  color: black !important;
  margin-left: 10px !important;
}

/********************************************Login Page  *********************************************/
.loginPageImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/login-images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.loginPageInnerContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  max-width: 488px;
  height: fit-content;
  padding: 30px 22px;
  border-radius: 5px;
}
.loginHeader {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.loginSubText {
  color: #929497;
  font-size: var(--subheader-font-size);
  margin-bottom: 40px;
}
.loginButton {
  display: flex;
  justify-content: center !important;
  width: 100% !important;
  box-shadow: 0px 3px 6px rgb(0,0,0,16%) !important;
}
.loginButton:hover, .loginButton:hover * {
  color: white !important;
  background-color: rgb(211, 205, 205) !important;
}

/****************************************** Navigation Bar **********************************************/
nav.deskNav {
  position: sticky;
  top: 0;
  height: 130px;
  background-color: var(--primary-color);
  padding: 5px 10px 5px 10px;
  display: grid;
  grid-template-columns: 255px minmax(150px, 930px);
  justify-content: center;
  align-items: center;
  grid-column-gap: 20px;
  /* Jeff Neely's suggestion */
  z-index: 5;
}
nav.mobileNav {
  position: sticky;
  top: 0;
  height: 110px;
  background-color: var(--primary-color);
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 5;
}
.navBarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.navLinkContainer {
  width: 100%;
  padding-top: 25px;
}
.mobileSearchFilterContainer {
  display: flex;
  padding: 15px 10px 15px 10px;
  position: sticky;
  top: 110px;
  background-color: white;
  z-index: 4;
}
.mobileNavBackButtonContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.mobileSearchFilterContainerDetailPage {
  display: flex;
  /* bottom padding only difference */
  padding: 15px 10px 15px 10px;
  position: sticky;
  top: 110px;
  background-color: white;
  z-index: 4;
}
.mobileBackButton {
  cursor: pointer;
  position: absolute;
  left: 10px;
}
.mobileFilterButton {
  margin-left: 22px;
  margin-right: 6px;
  cursor: pointer;
}
.mobileFilterButtonInactive {
  margin-left: 22px;
  margin-right: 6px;
}
.navItem {
  color: white;
  font-size: var(--header-font-size);
  margin-right: 30px;
  text-decoration: none;
}
.navItem:hover {
  color: white;
  text-decoration: none;
}
#activeLink {
  padding-bottom: 2px;
  border-bottom: 2px solid white;
}
.mainLogo {
  cursor: pointer;
}
.gizmosHeader {
  height: 52px;
  background-color: #E0E0E1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 28px;
  margin-left: 30px;
}
.logoutButton {
  background-color: #E0E0E1;
  width: 80px;
  padding: 11px 16px;
  border-radius: 8px;
  font-size: 14px;
  color: #1A5B93;
  cursor: pointer;
  margin-right: 14px;
  font-weight: bold;
}
.logoutButton:hover {
  background-color: #D0D1D2;
}
@media screen and (max-width: 600px) {
  .logo {
    height: 15px;
    margin-left: 16px;
  }
  .logoutButton {
    margin-right: 8px;
  }
}

/*************************************** Search Bar **********************************************/
.outerSearchBarContainer {
  width: 100%;
  height: 40px;
  background-color: var(--light-grey-color);
  /* display: flex;
  flex-direction: column; */
  border-radius: 5px;
}
.innerSearchBarContainer {
  display: flex;
}
.searchBar {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: var(--light-grey-color);
  border: 0px;
  outline: none;
  padding-left: 10px;
}
.searchIcon {
  cursor: pointer;
  content: url('../assets/searchIcon.png');
}
.searchIcon:hover {
  content: url('../assets/search-button-hover.svg');
}
.searchResultList {
  background-color: var(--light-grey-color);
  height: fit-content;
  max-height: 400px;
  overflow-y: hidden;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 6px rgb(0,0,0,16%);
}
.searchItem {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  cursor: pointer;
}
.searchItem:hover, .searchItem:active {
  background-color: var(--accent-color);
}
.searchHelperText {
  color: #595C5F;
  margin-left: 5px;
}
.noSearchResultsText {
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  margin-top: 20px;
  font-size: var(--header-font-size);
}


/********************************************* Branch & Oninite List Pages ******************************************************/

/************** List Headers **************/
.listHeaderContainer {
  background-color: var(--primary-color);
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}
.listHeaderContainer_subRegion {
  background-color: var(--accent-color);
  padding-left: 10px;
  padding-right: 10px;
}
.listHeaderContainer_subRegion2 {
  background-color: #80A4C3;
  padding-left: 10px;
  padding-right: 10px;
}
.listHeaderContainer_lvl1 {
  background-color: var(--primary-color);
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}
.listHeaderContainer_lvl2 {
  background-color: #80A4C3;
  padding-left: 10px;
  padding-right: 10px;
}
.listHeaderContainer_lvl3 {
  background-color:#ccddeb;
  padding-left: 10px;
  padding-right: 10px;
}
.listHeaderContainer_lvl4 {
  background-color:#e8f1f7;
  padding-left: 10px;
  padding-right: 10px;
}
.listHeaderContainer_lvl5 {
  background-color:#dae7ef;
  padding-left: 10px;
  padding-right: 10px;
}

.listHeaderContainer_homeOffice {
  background-color: var(--primary-color);
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeaderContainer_homeOffice_IT {
  background-color: var(--accent-color);
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.homeOfficeListItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homeOfficeWhoContactContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.listHeader {
  color: white;
  font-size: var(--header-font-size);
  font-weight: bold;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_subRegion {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_lvl1 {
  color: white;
  font-size: var(--header-font-size);
  font-weight: bold;
  height: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_lvl2 {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_lvl3 {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_lvl4 {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_lvl5 {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.listHeader_homeOffice_IT {
  color: black;
  font-size: var(--primary-font-size);
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
}
.listHeader_homeOffice {
  color: white;
  font-size: var(--header-font-size);
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
}
.listHeader_homeOffice_Link {
  padding-right: 5px;
}
.stickyHeader_lvl1{
  position: sticky;
  top: 204px; 
}
.stickyHeader_lvl2{
  position: sticky;
  top: 234px; 
}
.stickyHeader_lvl3{
  position: sticky;
  top: 261px; 
}

.stickyHeader_lvl4{
  position: sticky;
  top: 315px; 
}
.stickyHeader_lvl5{
  position: sticky;
  top: 342px; 
}

.stickyHeader {
  position: sticky;
  top: 204px; 
}
.stickyHeader_subRegion {
  position: sticky;
  top: 234px;
}
.stickyHeader_subsubRegion {
  position: sticky;
  top: 261px;
}
.stickyHeader_subHomeOffice {
  position: sticky;
  top: 384px;
}
.stickyHeader_BranchDetail {
  position: sticky;
  top: 354px; 
}
.stickyHeader_OniniteDetail {
  position: sticky;
  top: 346px; 
}
.stickyHeader_OniniteDetail_Phone {
  position: sticky;
  top: 379px; 
}
.stickyHeaderMobile {
  position: sticky;
  top: 180px; 
}
.stickyHeaderMobile_subRegion {
  position: sticky;
  top: 210px; 
}
.stickyHeaderMobile_Detail {
  position: sticky;
  top: 180px;
}
.stickyHeaderMobile_subHomeOffice {
  position: sticky;
  top: 210px;
}
/* **************************************** */

/* Mobile Modal Filter for Branch/Oninite Page*/
.modal {
  display: flex;
  justify-content: center;
  z-index: 5;
  background-color: rgb(16,24,32,16%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 195px;
  position: fixed;
  top: 174px;
  border-radius: 14px;
  /* to account for 10px L & R ? */
  width: 96%;
}
.modalContentEmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 157px;
  position: fixed;
  top: 174px;
  border-radius: 14px;
  /* to account for 10px L & R ? */
  width: 96%;
}
.modalLineItem {
  height: 40px;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #D0D1D2;
  width: 100%;
  text-align: center;
  color: var(--primary-color);
}
.modalLineItemActive {
  background-color: var(--accent-color);
}
.modalLineItem:not(:first-child):active {
  background-color: var(--accent-color);
}
.modalLineItem:first-child {
  color: #636569;
  font-size: var(--subheader-font-size);
}
.modalLineItem:last-child {
  border-bottom: none;
}
/* ******************************************* */

/************************ Google Sheet Modal ***************************/
.googleModal {
  display: flex;
  justify-content: center;
  z-index: 6;
  background-color: rgb(16,24,32,16%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.googleModalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  background-color: white;
  color: white;
  height: 150px;
  position: fixed;
  top: 204px;
  border-radius: 14px;
  padding: 10px;
  background-image: url('../assets/loadingScreen.svg');
}
.googleModalText {
  font-family: Comic Sans MS, Comic Sans, cursive;
  font-size: var(--header-font-size);
  font-weight: bold;
}
.googleModalButton {
  width: 75px;
  border: 2px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  border-radius: 10px;
  height: 35px;
  font-size: var(--header-font-size);
  font-family: Comic Sans MS, Comic Sans, cursive;
  cursor: pointer;
}
.googleModalButton:hover {
  color: white;
  background-color: var(--primary-color);
}
/*******************************  ****************************************/

.sortContainer {
  position: sticky;
  top: 130px;
  height: 74px;
  background-color: white;
  display: grid;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px;
  z-index: 4;
}
.sortInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
}
.branchSupportOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.branchSupportAddLinkContainer {
  display: flex;
  margin-top: 10px;
}
.branchSupportIcon {
  margin-right: 5px;
  cursor: pointer;
}
.addBranchLink {
  margin-right: 15px;
}
.branchContactAndEditContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.branchEditCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 35px;
  margin-bottom: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  color: var(--primary-color);
  cursor: pointer;
}
.branchEditCardContainer:hover {
  background-color: var(--primary-color);
  color: white;
}
.branchEditCardContainer:hover .editBranchIcon {
  fill: white;
}
.editBranchIcon {
  fill: var(--primary-color);
  margin-left: 5px;
}
.sortButton {
  width: 205px;
  height: 48px;
  border-radius: 5px;
  border: 0.5px solid var(--border-color);
  padding-left: 12px;
}
.branchAllSelectsContainer {
  display: flex;
}
.select-wrapper {
  margin-left: 20px;
}
.selectButtonContainer {
  display: flex;
  align-items: center;
  width: 205px;
  border: 0.5px solid var(--border-color);
  justify-content: space-between;
  padding: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.selectList {
  position: absolute;
  top: 63px;
  background-color: white;
  width: 205px;
  border: 0.5px solid var(--border-color);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 3px 6px rgb(0,0,0,16%);
}
.selectListItem {
  padding: 16px 0px 16px 16px;
  color: var(--primary-color);
  cursor: pointer;
  border-bottom: 0.5px solid var(--border-color);
}
.selectListItem:last-child {
  border-bottom: none;
}
.selectListItem:hover {
  background-color: var(--accent-color);
}
.branchNameIconContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.itemContainer {
  display: grid;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px 0px 10px; 
}
.evenNumBranchBackColor {
  background-color: var(--light-grey-color);
}
.oddNumBranchBackColor {
  background-color: white;
}
.desktopBranchContactContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  max-width: 800px;
  width: 100%;
  height: 100px
}
.desktopOniniteContactContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 578px));
  grid-row-gap: 15px;
  margin-bottom: 20px;
  /* height: 51px; */
}
.mobileOniniteContactContainer {
  display: grid;
  grid-row-gap: 15px;
  margin-bottom: 20px;
  /* height: 117px; */
}
.mobileBranchContactContainer {
  margin-bottom: 10px;
  /* height: 155px; */
  height: fit-content;
}
.oniniteAdminContactEditContainer {
  display: flex;
  align-items: flex-end;
}
.branchLineItemContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 15px;
}
.oniniteLineItemContainer {
  display: flex;
  align-items: center;
}
.homeBranchGridCell {
  grid-column: auto;
  grid-row: auto;
}
.emailGridCell {
  grid-column: 2/3;
  grid-row: 1/2;
}
.managerGridCell {
  grid-column: 1/2;
  grid-row: auto;
}
.phoneGridCell {
  grid-column: 2/2;
}
.adressLinePrimary {
  padding-bottom: 5px;
}
.desktopBranchItemContainer {
  width: 25%;
}
.addressContainer {
  display: flex;
  flex-direction: column;
}
.addressContainer:hover a {
  /* color: var(--link-hover-color); */
  text-decoration: underline;
}
.addressIcon {
  /* to account for a width difference in 
  the svg to align with the others */
  margin-right: 16px;
}
.branchContactIcon, .oniniteContactIcon {
  margin-right: 10px;
}
.bambooPhoto {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin-right: 10px;
}
.oniniteImageNameOuterContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 18px;
}
.oniniteTitleDeptContainer {
  display: flex;
  flex-wrap: wrap;
}
p#branchName {
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: var(--header-font-size);
  color: black;
}
.branchManagerName {
  margin-top: 20px;
  font-weight: normal !important;
  cursor: pointer;
  font-size: var(--header-font-size);
  color: black;
}
p#branchNameDetail {
  margin-top: 20px;
  font-weight: bold;
  font-size: var(--header-font-size);
  color: black;
}
.branchManagerNameDetail {
  margin-top: 20px;
  font-weight: normal !important;
  font-size: var(--header-font-size);
  color: black;
}
p.oniniteName {
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: var(--header-font-size);
  color: black;
}
p.oniniteNameDetail {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: var(--header-font-size);
  color: black;
}
p#branchName:hover, p.oniniteName:hover {
  text-decoration: underline;
}
.regionManagerHeader {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 12px;
  max-width: 75%;
}
#regionManagerName {
  margin-left: 5px;
}
.downloadButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.downloadButton {
  margin-right: 5px;
}
.branchIconOffset {
  padding-left: 8px;
}

/***************************************** Branch & Oninite Detail Pages ************************************************/
.desktopBackButtonContainer {
  display: grid;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 16px 10px 10px 10px;
  position: sticky;
  top: 130px;
  background-color: white;
}
.detailHeaderStickyContainer {
  position: sticky;
  top: 173px;
}
.branchDetailGreyDivider {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 30px;
}
.branchDetailAdminInfoContainer {
  display: grid;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
}
.branchDetailAdminInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}
.branchDetailAdminButtonContainer {
  display: flex;
}
.branchDetailOpenBranch {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px;
}
.branchDetailClosedBranch {
  background-color: var(--cancel-red);
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px;
}
.branchDetailAdminHeader {
  font-weight: bold;
  margin-bottom: 15px;
}
.branchDetailAdminHeaderFinal {
  font-weight: bold;
}
.branchDetailHeaderContent {
  font-weight: normal;
}
.detailEditCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 35px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  color: var(--primary-color);
  cursor: pointer;
  /* margin-right: 20px; */
}
.detailEditCardContainer:hover {
  background-color: var(--primary-color);
  color: white;
}
.detailEditCardContainer:hover .editBranchIcon {
  fill: white;
}

.branchDetailCloseModalButton {
  /* width: 108px; */
  height: 39px;
  color: var(--cancel-red);
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid var(--cancel-red);
  margin-left: auto;
}
.branchDetailCloseModalButton:hover {
  background-color: var(--cancel-red);
  color: white;
}
.branchDetailReopenModalButton {
  width: 126px;
  height: 39px;
  color: var(--submit-green);
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid var(--submit-green);
  margin-left: auto;
}
.branchDetailReopenModalButton:hover {
  background-color: var(--submit-green);
  color: white;
}
.reopenButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  padding: 0 12px;
  height: 35px;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
}
.reopenButtonContainer:hover {
  background-color: var(--primary-color);
  color: white;
}
.reopenButtonContainer:hover .reopenButton {
  fill: white;
}
.reopenButton {
  margin-left: 3px;
}

/********************************************************** All Support Pages ********************************************************/
.inputBottomSpacing {
  margin-bottom: 25px;
}
.radioHeaderBottomSpacing {
  margin-bottom: 15px;
}
.radioButtonBottomSpacing {
  margin-bottom: 10px;
}
.subHeaderBottomSpacing {
  margin-bottom: 4px;
}
.subHeaderHelperText {
  color: #999999;
}
.branchButtonContainer {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  /* padding: 0px 10px 0px 10px;
  justify-items: end; */
}
.cancelButton {
  width: 85px;
  height: 40px;
  background-color: white;
  border: 2px solid var(--cancel-red);
  border-radius: 10px;
  color: var(--cancel-red);
  cursor: pointer;
  margin-left: 18px;
}
.cancelButton:hover {
  color: white;
  background-color: var(--cancel-red);
}
.updateButton {
  width: 85px;
  height: 40px;
  background-color: var(--submit-green);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.updateButton:hover, .addBranchButton:hover {
  background-color: var(--submit-green-hover);
}
.addBranchButton {
  width: 116px;
  height: 40px;
  background-color: var(--submit-green);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.nextButton {
  width: 85px;
  height: 40px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.nextButton:hover {
  background-color: var(--next-button-hover);
}
.mainBranchContainer {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px 0px 10px;
  margin-top: 30px;
}
.branchInputOuterContainer {
  display: flex;
  column-gap: 30px;
}
.editBranchLocationHeaderContainer, .branchInputColumnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 388px;
}
.branchInput {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 0px 10px 15px;
  width: 100%;
  max-width: 388px;
}
.smallInputContainer {
  width: 100%;
  max-width: 187px;
}
.branchInputSmall {
  width: 100%;
  max-width: 187px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 0px 10px 15px;
}
.stateZipContainer, .phoneFaxContainer {
  display: flex;
  justify-content: space-between;
}
.branchRadioButton {
  margin-right: 10px;
  accent-color: var(--primary-color);
}

.errorInputBorder {
  border: 2px solid var(--cancel-red);
}
.errInputSubText {
  color: var(--cancel-red);
  font-size: var(--subheader-font-size);
}
.supportConfirmModal {
  display: flex;
  justify-content: center;
  z-index: 6;
  background-color: rgb(16,24,32,16%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.supportConfirmModalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  background-color: white;
  color: black;
  height: fit-content;
  position: fixed;
  top: 250px;
  border-radius: 5px;
  padding: 30px;
}
.supportConfirmModalText {
  font-size: var(--header-font-size);
  margin-bottom: 30px;
}
.supportConfirmModalButton {
  width: 75px;
  border: 2px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  border-radius: 10px;
  height: 35px;
  font-size: var(--header-font-size);
  cursor: pointer;
}
.supportConfirmModalButton:hover {
  color: white;
  background-color: var(--primary-color);
}


/***************************************************** Edit Oninite Support Page **********************************************************/
.mainOniniteContainer {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px 0px 10px;
  margin-top: 30px;
}
.editOniniteHeaderCard {
  border-bottom: 0.5px solid #D0D1D2;
  max-width: 1205px;
  margin: 0 auto;
  margin-top: 25px;
}
.editOniniteBottomSpacing {
  margin-bottom: 20px;
}
.editOniniteHeader {
  font-weight: bold;
  font-size: var(--header-font-size);
}
.editOniniteColumnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 388px;
}
.additionButton {
  margin-left: 10px;
  cursor: pointer;
}
.additionButton:hover {
  fill: var(--next-button-hover);
}
.subtractionButton {
  cursor: pointer;
}
.subtractionButton:hover {
  fill: var(--cancel-red-hover);
}
.addAssocBranchContainer {
  display: flex;
  align-items: center;
}
.additionalBranch {
  max-width: 375px;
}
.searchBranchResultList {
  background-color: white;
  height: fit-content;
  max-height: 210px;
  overflow-y: hidden;
  border: 1px solid;
  border-radius: 5px;
  width: 357px;
  z-index: 2;
  position: absolute;
}
.searchBranchItem {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  height: 35px;
}
.searchBranchItem:hover {
  background-color: var(--accent-color);
}
.editOniniteSearchBranchInput {
  width: 357px;
}
.editOniniteBranchSubtractContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

/***************************************************** Edit Branch Support Page **********************************************************/
.editBranchHeaderCard {
  border-bottom: 0.5px solid #D0D1D2;
  max-width: 1205px;
  margin: 0 auto;
  margin-top: 25px;
}
.editBranchBottomSpacing {
  margin-bottom: 20px;
}
.editBranchHeader {
  font-weight: bold;
  font-size: var(--header-font-size);
}
.disabledInputs {
  opacity: 20%;
  pointer-events: none;
}

/********************************************** Add Branch Support Page ******************************************************/
.addBranchMainContainer {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px 0px 10px;
  margin-top: 30px;
}
.addBranchHeader {
  font-weight: bold;
  font-size: var(--header-font-size);
  margin-bottom: 30px;
}
.addBranchFormSecondColumnAdj {
  margin-top: 20px;
}

/************************************************* Manage Region Support Page ***********************************************************************/
.regionMainContainer {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(150px, 1205px);
  justify-content: center;
  padding: 0px 10px 0px 10px;
  margin-top: 30px;
}
.regionHeader {
  font-weight: bold;
  font-size: var(--header-font-size);
  margin-bottom: 30px;
}
.regionHeaderList {
  font-weight: bold;
  font-size: var(--header-font-size);
  margin-bottom: 15px;
}
.regionGreyDivider {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}
.regionHeaderLinkContainer {
  display: flex;
  justify-content: space-between;
}
.regionAddRegionContainer {
  display: flex;
}
.manageRegionEditLineItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 10px;
}
.manageRegionEditLineItemContainer:hover {
  background-color: var(--light-grey-color);
}
.regionEditCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 35px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  color: var(--primary-color);
  cursor: pointer;
}
.regionEditCardContainer:hover {
  background-color: var(--primary-color);
  color: white;
}
.regionEditCardContainer:hover .editBranchIcon {
  fill: white;
}
.manageRegionLineHeader {
  font-weight: bold;
}
.manageRegionInputOuterContainer {
  display: flex;
  column-gap: 150px;
}
.manageRegionInputColumnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 388px;
}
.editRegionBranch {
  margin-bottom: 15px;
}
.editRegionHeaderDeactivateContainer {
  display: flex;
  justify-content: space-between;
}
.deactivateButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  padding: 0 12px;
  height: 35px;
  color: var(--cancel-red);
  border: 2px solid var(--cancel-red);
  border-radius: 10px;
  cursor: pointer;
}
.deactivateButtonContainer:hover {
  background-color: var(--cancel-red);
  color: white;
}
.deactivateButtonContainer:hover .deactivateIcon {
  fill: white;
}
.deactivateIcon {
  margin-left: 3px;
}
.searchOniniteResultList {
  background-color: white;
  height: fit-content;
  max-height: 210px;
  overflow-y: hidden;
  border: 1px solid;
  border-radius: 5px;
  width: 388px;
  z-index: 2;
  position: absolute;
}

/* Edit Region Deactivate Modal */
.editRegionDeactivateModal {
  display: flex;
  justify-content: center;
  z-index: 6;
  background-color: rgb(16,24,32,16%);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.editRegionDeactivateModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  height: 150px;
  position: fixed;
  top: 204px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  height: fit-content;
  border-radius: 5px;
}
.xClose {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.xClose:hover * {
  fill: #636569;
}
.deactivateHeader {
  font-size: var(--header-font-size);
  font-weight: bold;
  margin-bottom: 30px;
}
.deactivateMainText {
  margin-bottom: 30px;
}
.deactivateRegionsSelect {
  margin-bottom: 30px;
}
.deactivateModalButton {
  width: 108px;
  height: 39px;
  color: white;
  background-color: var(--cancel-red);
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid var(--cancel-red);
  margin-left: auto;
}
.deactivateModalButton:hover {
  background-color: var(--cancel-red-hover);
}


.logoutContainer {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10
}
.logoutInnerContainer {
  display: flex;
  width: 100%;
  border-top: 2px solid #E0E0E1;
}
.logoutInnerInnerContainer {
  display: flex;
  cursor: pointer;
  margin-left: 34px;
  padding: 8px;
}
.logoutInnerInnerContainer:hover * {
  text-decoration: underline;
}
.logoutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  font-weight: bold;
  font-size: 14px;
  color: #1A5B93;
  cursor: pointer;
  border-radius: 8px;
}
.logoutButton:hover {
  background-color: #E0E0E1;
}
.userInfoContainer {
  position: absolute;
  top: 38px;
  right: 10px;
  color: black;
  border: 1px solid #E0E0E1;
  border-radius: 8px;
  background-color: white;
}
.userPhoto {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}
.userPhotoNonClick {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 5px;
}
.logoutText {
  color: #004987;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}